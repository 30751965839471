import React from "react"
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { motion } from "framer-motion"
import { BrowserView, MobileView } from "react-device-detect";


const LandingHero = ({content}) => {

  const plainTextContent = content.companyValue.json

  const Bold = ({ children }) => <span className="font-extrabold">{children}</span>;
  const Italic = ({ children }) => <span className="italic text-xl">{children}</span>;

  const options_plainText = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node,children) => <p className="my-2">{children}</p>,
    },
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
      [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    },
  }

  return (
    <>
    <BrowserView>
      <div className="">
        <BackgroundImage
          className="hero-landing-bg"
          fluid={content.heroImage.fluid}
          fadeIn
        >
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="black-overlay flex flex-col justify-end items-center">
            <div className="text-center text-base lg:text-lg text-white w-2/3 md:w-1/2 lg:w-1/3 lg:mb-4 xl:mb-6 2xl:mb-8 3xl:mb-20">{plainTextContent && documentToReactComponents(plainTextContent, options_plainText)}</div>
              <div className="hidden landing-content-box text-right lg:text-center">
              {content.tagline && content.tagline.map((line,index)=>{
                  return <p className="mr-2 text-white text-4xl lg:text-5xl italic" key={`landing-tagline-${index}`}>{line}</p>
              })}
              </div>
          </motion.div>
        </BackgroundImage>
      </div>      
    </BrowserView>
    <MobileView>
      <div className="bg-custombrick pt-32 h-full">
        <div className="w-full pt-8 my-auto">
          <Img style={{width:"100%"}} fluid={content.heroImage.fluid}/>
        </div>
        <div className="container text-white text-center mx-auto my-auto py-12 max-w-full">
          <div className="text-center">
            {plainTextContent && documentToReactComponents(plainTextContent, options_plainText)}
          </div>
        </div>
      </div>
    </MobileView>
    </>
)}

export default LandingHero
