import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import LandingHero from "../components/Hero/LandingHero"
import LandingSecond from "../components/Hero/LandingSecond"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Home" description="Landing Page of Yae Works" />

      <LandingHero content={data.first} />
      <LandingSecond content={data.second} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query LandingQuery {
    first: contentfulPageHero(title: {eq: "Landing > First"}) {
      title
      tagline
      companyValue {
        json
      }
      heroImage {
        fluid (maxWidth: 2000, quality: 100){
          ...GatsbyContentfulFluid
        }
      }
    }
    second: contentfulPageHero(title: {eq: "Landing > Second"}) {
      title
      heroImage {
        fluid (maxWidth: 2000, quality: 100){
          ...GatsbyContentfulFluid
        }
      }
      heroText
    }
  }
`
